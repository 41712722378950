
export default function GovernanceHeader() {
  return (
    <div className="container-fluid gov mt-0 section-padding">
      <div className="container mt-0 pt-0">
        <div className="row">
          <div className="col-xs-12 pl-2 mt-0">
            <div className="col-xs-6 d-flex justify-content-between">
              <h1 className="text-light font-weight mt-4 heading font-weight-bold">
                Governance
              </h1>
              <div>
                <span className="btn btn-warning gellow mt-4 gov-btn" href="">
                  Create a new proposal
                </span>
              </div>
            </div>
            <hr className="gov-line"/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="tile m-2">
              <h3 className="font-weight-bold">0</h3>
              <h4> Total Proposals</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="tile m-2">
              <h3 className="font-weight-bold">0</h3>
              <h4> Voting Delegated</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="tile m-2">
              <h3 className="font-weight-bold">0</h3>
              <h4> Voting Addresses</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="tile m-2">
              <h3 className="font-weight-bold">0</h3>
              <h4> Staking Pools</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

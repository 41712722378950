import React from "react";

function P2e() {
  return (
    <div className="cotainer-fluid section-padding">
      <div className="container">
        <div className="row display-4">
          <div className="col-md-12 p2e-text">
            <h1 data-aos='fade-down' data-aos-delay='300'>How to P2E</h1>
            <hr />
            <p data-aos='fade-up' data-aos-delay='500'>
              The Monverse game has a frantic gameplay that maintains the <br className="line-break"/>
              players interest and provides them an adrenaline rush.
            </p>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-12">
            <div className="p2e-img">
              <img
                src={"/assets/fliw.svg"}
                alt=""
                className=""
                data-aos='zoom-in' data-aos-delay='300'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default P2e;

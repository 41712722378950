import React from 'react'

import Gov from '../components/elements/goverence/Index'

function Goverence() {
  return (
    <>
    <Gov/>
    </>
  )
}

export default Goverence
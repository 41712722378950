import React from "react";
import {TiTick} from 'react-icons/ti'
function Roadmap() {
  return (
    <div className="container-fluid section-padding roadmap-bg " id="roadmap">
      <div className="container">
        <div className="row display-7">
          <div className="col-md-12 blockmover-row2">
            <h1 data-aos='fade-down' data-aos-delay='300'>ROADMAP</h1>
            <hr />
            <p data-aos='fade-up' data-aos-delay='500'>A road down the adventures of Monverse</p>
          </div>
        </div>

        <div className="row mt-5">
            <div className="col-md-3">
                <div className="roadmap-box" data-aos='fade-right' data-aos-delay='100'>
                    <div>
                        <h1>Q1</h1>
                        <TiTick className="tick"/>
                    </div>
                    <p>Completed</p>

                    <ul className="list-unstyled">
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Idea development</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Game feasibility</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Research and development</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>On boarding team members</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>NFT design and development</li>
                    </ul>
                </div>
            </div>

            <div className="col-md-3">
                <img src={process.env.REACT_APP_S3_URL +"/assets/Vector.svg"} alt="" className="mb-4" />
                <div className="roadmap-box" data-aos='fade-up' data-aos-delay='300'>
                    <div>
                        <h1>Q2.1</h1>
                        <TiTick className="tick"/>
                    </div>
                    <p>Completed</p>

                    <ul className="list-unstyled">
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Documentation release</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>G1st phase of game development</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Phase 1 beta testing</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Game features testing</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Strategic partnership</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Airdrop reward</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Reward integration</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Storybook release</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Public IGO sale</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Phase 2 game development</li>
                    </ul>
                </div>
            </div>
            <div className="col-md-3">
            <div className="roadmap-box" data-aos='fade-down' data-aos-delay='500'>
                    <div>
                        <h1>Q2.2</h1>
                        <TiTick className="tick"/>
                    </div>
                    <span>Q2 2024</span>

                    <ul className="list-unstyled">
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Phase beta testing</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Mint NFTs</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Staking reward</li>
                    </ul>
                </div>
                <div className="roadmap-box mt-2" data-aos='fade-up' data-aos-delay='500'>
                    <div>
                        <h1>Q3</h1>
                        <TiTick className="tick"/>
                    </div>
                    <span>Q3 2024</span>

                    <ul className="list-unstyled">
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Fighting character trading on marketplace</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Support other blockchains</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Automate reward system</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Add more character variation</li>
                    </ul>
                </div>
                <img src={process.env.REACT_APP_S3_URL +"/assets/Vector1.svg"} alt="" className="mt-4" style={{float:'right'}} />
            </div>
            <div className="col-md-3">
                <img src={process.env.REACT_APP_S3_URL +"/assets/Vector.svg"} alt="" className="mb-4" />
                <div className="roadmap-box" data-aos='fade-down' data-aos-delay='300'>
                    <div>
                        <h1>Q4</h1>
                        <TiTick className="tick"/>
                    </div>
                    <span>Q3 2024</span>

                    <ul className="list-unstyled">
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Partnership with other gaming project</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>PVP game integration</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>NFT renting</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Paypal credit card payment method</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Game quality assurance and testing</li>
                    </ul>
                </div>
                <div className="roadmap-box mt-2" data-aos='fade-up' data-aos-delay='500'>
                    <div>
                        <h1>Q5</h1>
                        <TiTick className="tick"/>
                    </div>
                    <span>Q3 2024</span>

                    <ul className="list-unstyled">
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Binance NFT marketplace listing</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Onboard venture capitalist</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Release NFT 2.0 version</li>
                        <li><img src={process.env.REACT_APP_S3_URL +"/assets/tickbag.svg"} alt=""  className="mx-1"/>Metaverse utility integration </li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;

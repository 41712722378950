import './App.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home'
import Base from './components/layouts/Base'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'react-multi-carousel/lib/styles.css';
import { useEffect } from 'react';
// import "notiflix/dist/notiflix-3.2.5.min.css";
// import "notiflix/dist/notiflix-3.2.5.min.js";
import Goverence from './pages/Goverence';

import ReactGA from 'react-ga';
ReactGA.initialize('G-HSFGW8TYC7');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  useEffect(()=>{
    AOS.init({duration: 1500})
  })
  return (
    <>
      <BrowserRouter>
      <Base>
      <Routes>
        <Route index path="/" element={<Home/>}/>
        <Route path='/gov' element={<Goverence/>}/>
      </Routes>
      </Base>
      </BrowserRouter>
    </>
  );
}

export default App;

/** @format */

import React from "react";
import CountDown from "../utils/Countdown";

function Hero() {
  return (
    <div className='container-fluid hero-bg section-padding' id='home'>
      <div className='row pt-5'>
        <div className='col-lg-12'>
          <div className='row time-con m-auto'>
            {/* <div className='col-sm-4 m-auto timer'>
              <CountDown />
            </div> */}
            <div className='col-sm-9 m-auto'>
              <h1 className='text-light'>We are on Solana Chain</h1>
            </div>
            <div className='col-sm-3 m-auto'>
              <a
                href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=BVyHzByZEEuiVynUZShrgYqx95Yp9PWGYxbeGAiNHFru&outputSymbol=Monstr&fixed=in'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <button className='hero-btn w-100 d-block m-auto'>
                  <img
                    src='assets/Raydium.svg'
                    style={{ display: "inline-block", width: 34 }}
                    alt=''
                  />{" "}
                  &nbsp; Trade on Raydium
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 hero-text'>
          <img
            src={"/assets/hero-logo.png"}
            alt=''
            loading='lazy'
            className='hero-img res-img'
            data-aos='fade-down'
          />
          <h1 data-aos='fade-right'>
            Welcome to the world of <br /> multi-chain monsters.
          </h1>
          <h2 data-aos='zoom-in' aos-delay='300'>
            Capture.Collect and Commerce
          </h2>
          <h3 data-aos='fade-up'>
            Earn while you hunt and collect monsters on the multi-chain
          </h3>
          <a href='#sec1'>
            <img
              src={"/assets/Frame.svg"}
              alt=''
              className='mt-5'
              data-aos='zoom-in'
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Hero;

import React from "react";
import Faq from "react-faq-component";

const data = {
  //   title: "FAQ (How it works)",
  rows: [
    {
      title: "How can I play Monverse?",
      content: `You can play monverse by login in the game, where you will be guided through the game manual by the game admin`,
    },
    {
      title: "On which blockchains can I play Monverse?",
      content:
        "You can play Monverse on Solana, Avalanche, BSC, Moonbeam and others",
    },
    {
      title: "How can I convert my coins into game currency?",
      content: `Yo can exchange your coins into FIRE tokens from your MetaMask wallet.`,
    },
    {
      title: "Can I use my game earnings for investing?",
      content: `Yes, you can use your FIRE tokens and NFTs for our different staking pools`,
    }
  ],
};

const styles = {
  bgColor: "transparent",
  titleTextColor: "#EBEEF2",
  rowTitleColor: "#EBEEF2",
  rowContentColor: "#A5ABB3",
  arrowColor: "#FFF",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingLeft: "30px",
  rowContentPaddingRight: "30px",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  // tabFocus: true
  margin: "2px",
};

export default function Faqs() {
  return (
    <div className="container section-padding">
      <div className="row res-footer display-8">
          <div className="col-md-12 blockmover-row2"   data-aos="fade-up"
          data-aos-duration="1500">
            <h1>FAQ's</h1>
            <hr />
            <p>
              Frequently Asked Question
            </p>
          </div>

          <div data-aos='fade-right' data-aos-delay='500'>
            <Faq data={data} styles={styles} config={config} />
          </div>
        </div>
    </div>
  );
}

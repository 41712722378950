import React from "react";
import BlockMovers from "../components/elements/BlockMovers";
import BmcTokken from "../components/elements/BmcTokken";
import Hero from '../components/elements/Hero'
import P2e from "../components/elements/P2e";
import Partners from "../components/elements/Partners";
import Roadmap from "../components/elements/Roadmap";
import Section1 from "../components/elements/Section1";
// import Team from "../components/elements/Team";
import Faq from '../components/utils/Faqs'
function Home() {
  return (
    <>
      <Hero />
      <Section1/>
      <BmcTokken/>
      <BlockMovers/>
      <P2e/>
      <Partners/>
      {/* <Team/> */}
      <Roadmap/>
      <Faq/>
    </>
  );
}

export default Home;

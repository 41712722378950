    /** @format */

    import React from "react";

    import { IoGameControllerOutline } from "react-icons/io5";
    function Header() {
      return (
        <header>
          <nav className='navbar navbar-expand-lg fixed-top'>
            <div className='container'>
              <a className='navbar-brand' href='/'>
                <img
                  src={"/assets/Logo-1.png"}
                  alt=''
                  width={130}
                />
              </a>
              <button
                className='navbar-toggler'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'>
                <span className='navbar-toggler-icon' />
              </button>
              <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active document-active'
                      target='_blank'
                      rel='noreferrer'
                      href='https://monverse.gitbook.io/monverse-p2e-game-bsc/'>
                      Documents
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link  '
                      target='_blank'
                      rel='noreferrer'
                      href='/whitepaper.pdf'>
                      Whitepaper
                    </a>
                  </li>
                  <li>
                    <a
                      className='nav-link'
                      target='_blank'
                      rel='noreferrer'
                      href='https://github.com/solidproof/projects/blob/main/Monverse/SmartContract_Audit_Solidproof_Monverse.pdf'>
                      Audits
                    </a>
                  </li>
                  {/* <li className='nav-item'>
                    <a className='nav-link' href='#features'>
                      Features
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='#roadmap'>
                      Roadmap
                    </a>
                  </li> */}

                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='https://app.monverse.fun'
                      rel='noreferrer'
                      target='_blank'>
                      Marketplace
                    </a>
                  </li>
                  {/* <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='http://stake.monverse.fun/'
                      target='_blank'
                      rel='noreferrer'>
                      Staking
                    </a>
                  </li> */}
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='/gov'
                      target='_blank'
                      rel='noreferrer'>
                      Governance
                    </a>
                  </li>
                </ul>
              <a href="https://game.monverse.fun/" target="_blank" rel="noopener noreferrer"> <button className='nav-btn mx-4'>
                  <IoGameControllerOutline /> Play Now
                </button></a>
              </div>
            </div>
          </nav>
        </header>
      );
    }

    export default Header;

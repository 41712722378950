/** @format */

import React from "react";

function Section1() {
  const icons = [
    { imgUrl: "/assets/sol.svg" },
    { imgUrl: "/assets/bnb.png" },

    { imgUrl: "/assets/Icon1.svg" },
    { imgUrl: "/assets/Icon2.svg" },
    { imgUrl: "/assets/Icon3.svg" },

    { imgUrl: "/assets/Icon4.svg" },
    { imgUrl: "/assets/Icon5.svg" },
    { imgUrl: "/assets/Icon6.svg" },
  ];

  return (
    <div className='container-fluid section-padding sec1-bg ' id='sec1'>
      <div className='container'>
        <div className='row display-2'>
          <div className='col-md-6 sec1-text '>
            <div className='row'>
              <div className='col-md-6'>
                <h1 data-aos='fade-down' data-aos-delay='100'>
                  Introduction
                </h1>
                <hr data-aos='fade-right' data-aos-delay='200' />
              </div>
              <div className='col-md-6'>
                <a
                  href='https://github.com/solidproof/projects/blob/main/Monverse/SmartContract_Audit_Solidproof_Monverse.pdf'
                  target='_blank'
                  rel='noopener noreferrer'>
                  {" "}
                  <img
                    src='assets/solidproof.png'
                    className='w-75 d-block text-end'
                    alt=''
                  />
                </a>{" "}
              </div>
            </div>
            <p data-aos='fade-right' data-aos-delay='500'>
              Monverse is a multi-chain combat and strategy game, originally
              designed and developed on Solana. The game, inspired by the monsters,
              warriors and archers, revolves around collecting and defeating
              creatures that have dispersed all across the blockchain. The game
              is, both player-versus-player PVP, and
              Player-versus-the-environment PVE. In its both gameplays, the
              monverse game is developed on a layered reward structure that
              makes it define itself as a Play-to-Earn gaming project.
            </p>
            <div className='d-flex'>
              <p data-aos='fade-up' data-aos-delay='500'>
                Available on
              </p>
              <hr className='hr-2' />
            </div>
            <div className='row'>
              <div className='row'>
                {icons.map((v, i) => {
                  return (
                    <div
                      className='col-md-3 col-6'
                      key={i}
                      data-aos='fade-up'
                      data-aos-delay='500'>
                      <div className='sec1-icon-box d-flex  justify-content-center '>
                        <img
                          src={v.imgUrl}
                          alt=''
                          className='w-50 object-fit-contain '
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className='col-md-6 m-auto '>
            <img
              src={"assets/Videos.png"}
              alt=''
              style={{ width: "100%" }}
              data-aos='zoom-in'
              data-aos-delay='500'
            />
            {/* <video
              height='auto'
              autoplay='autoplay'
              loop
              muted
              playsinline
              class=' m-auto d-block w-100 mt-3'>
              <source src={"/assets/PC_Neon_1.mp4"} type='video/mp4' />
              Your browser does not support the video tag.
            </video> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;

/** @format */

import React from "react";
import Carousel from "react-multi-carousel";

function Partners(props) {
  const logos = [
    {
      imgUrl: "assets/partners/partner1.png",
      url: "https://twitter.com/bsc_daily",
    },
    {
      imgUrl: "assets/partners/partner2.png",
      url: "https://twitter.com/topbscnews",
    },
    {
      imgUrl: "assets/partners/partner3.png",
      url: "https://twitter.com/BSCNews",
    },
    {
      imgUrl: "assets/partners/partner4.png",
      url: "https://twitter.com/GamingIDO_",
    },
    // { imgUrl: "assets/pinksale.png", url: "https://www.pinksale.finance/launchpad/0xc7aA0bDf5d8808b5BD3a1575Ca932f8DF4b77645?chain=BSC" },
    // { imgUrl: "assets/pcs.png", url: "https://pancakeswap.finance/swap?outputCurrency=0x3933E1cDD51DE9b0f7d062AC8549cCdf63C219a9" },
    {
      imgUrl: "assets/solidproof.png",
      url: "https://github.com/solidproof/projects/blob/main/Monverse/SmartContract_Audit_Solidproof_Monverse.pdf",
    },
    {
      imgUrl: "assets/cg-logo.png",
      url: "https://www.coingecko.com/en/coins/monverse",
    },
    {
      imgUrl: "assets/cmc-logo.svg",
      url: "https://coinmarketcap.com/currencies/monverse/",
    },
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className='container-fluid section-padding partner-bg'>
      <div className='container'>
        <div className='row display-5'>
          <div className='col-md-2'>
            <div className='partners-text'>
              <h1 data-aos='fade-down' data-aos-delay='300'>
                WHAT SAY
              </h1>
              <hr />
            </div>
            <h1
              className='partners-bottom-text'
              data-aos='fade-up'
              data-aos-delay='500'>
              About Us
            </h1>
          </div>
          <div className='col-md-5'>
            <div
              className='card partner-cards'
              data-aos='fade-down'
              data-aos-delay='300'>
              <div className='card-body'>
                <p>
                  The Monverse project of countering the havoc caused by the
                  Monteva-Virus, is an exciting yet thrilling mission for
                  players on Solana, Ethereum, BSC and Moonbeam. The game,
                  story, cinematics, monsters and warriors are all highly
                  aesthetically pleasing. It is the game that would capture its
                  community’s interest for a long time.
                </p>
                <div className='d-flex justify-content-between'>
                  <img src={"/assets/bloomberg.svg"} alt='' />
                  <img src={"/assets/quote.svg"} alt='' />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div
              className='card partner-cards'
              data-aos='fade-up'
              data-aos-delay='300'>
              <div className='card-body'>
                <p>
                  The variety of income generation options on Monverse are
                  second to none. Monverse team has done a remarkable job of
                  their option designs, keeping in mind the wide spectrum of
                  investors.
                </p>
                <div className='d-flex justify-content-between'>
                  <img src={"/assets/yahoo."} alt='' />
                  <img src={"/assets/quote.svg"} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row blockmover-row2 mt-5 display-6'>
          <div className='col-md-12'>
            <h1 data-aos='fade-down' data-aos-delay='300'>
              PARTNERS
            </h1>
            <hr />
            <p data-aos='fade-up' data-aos-delay='500'>
              We take pride in our strong affiliation with other market leaders.{" "}
              <br className='line-break' />
              We proudly collaborate with them for a united cause.
            </p>
          </div>
        </div>

        {/* <marquee behavior="" direction="left"> */}
        <div className='row mt-5 '>
          <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            keyBoardControl={true}
            // customTransition="all .5"
            // transitionDuration={500}

            infinite={true}
            deviceType={props.deviceType}
            autoPlay={props.deviceType !== "mobile" ? true : false}
            showDots={false}>
            {logos.map((v, i) => {
              return (
                <span key={i} data-aos='fade-up' data-aos-delay='300'>
                  <a
                    href={v.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className=' '>
                    {" "}
                    <img
                      src={v.imgUrl}
                      alt=''
                      className='m-2 w-75 d-block m-auto res-img-logos'
                    />
                  </a>{" "}
                </span>
              );
            })}
          </Carousel>
        </div>
        {/* </marquee> */}
      </div>
    </div>
  );
}

export default Partners;

/** @format */

import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { FaRegCopy } from "react-icons/fa";
import Notiflix from "notiflix";

function BmcTokken() {
  const icons = [
    { imgUrl: "/assets/sol.svg" },
    { imgUrl: "/assets/bnb.png" },

    { imgUrl: "/assets/Icon1.svg" },
    { imgUrl: "/assets/Icon2.svg" },
    { imgUrl: "/assets/Icon3.svg" },

    { imgUrl: "/assets/Icon4.svg" },
    { imgUrl: "/assets/Icon5.svg" },
    { imgUrl: "/assets/Icon6.svg" },
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const copyy = () => {
    window.navigator.clipboard
      .writeText("BVyHzByZEEuiVynUZShrgYqx95Yp9PWGYxbeGAiNHFru")
      .then(() => {
        Notiflix.Notify.success("Copy Token Address");
      });
  };

  return (
    <div className='container-fluid section-padding ' id='features'>
      <div className='container'>
        <div className='row display-1'>
          <div className='col-md-12 blockmover-row2 '>
            <h1 data-aos='fade-right '>$Monstr TOKEN</h1>
            <hr />
            <p data-aos='fade-down'>
              The $Monstr token is the main currency of the Monverse project,
              thus having many transactional features.
            </p>
          </div>
          <Carousel responsive={responsive}>
            <div>
              <div
                className='card tokken-cards m-2'
                data-aos='fade-up'
                data-aos-delay='100'>
                <div className='card-body '>
                  <img
                    src={"/assets/Utility.svg"}
                    alt=''
                  />
                  <h1>Power Points</h1>
                  <p>
                    The power points in the game are FIRE tokens that can
                    easily be converted into $Monstr tokens.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div
                className='card tokken-cards m-2'
                data-aos='fade-up'
                data-aos-delay='200'>
                <div className='card-body '>
                  <img
                    src={"/assets/power.svg"}
                    alt=''
                  />
                  <h1>Governance</h1>
                  <p>
                    The $Monstr token enables users to participate on the
                    governance platform, and become the part of the change they
                    seek.
                  </p>
                </div>
              </div>
            </div>
            <div>
              {" "}
              <div
                className='card tokken-cards m-2'
                data-aos='fade-up'
                data-aos-delay='500'>
                <div className='card-body '>
                  <img
                    src={"/assets/Earn.svg"}
                    alt=''
                  />
                  <h1>Staking & farming</h1>
                  <p>
                    There are multiple options, such as, NFT staking, Staking,
                    and farming. Users can grow their assets exponentially on
                    Monverse.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div
                className='card tokken-cards m-2'
                data-aos='fade-up'
                data-aos-delay='600'>
                <div className='card-body '>
                  <img
                    src={"/assets/Life.svg"}
                    alt=''
                  />
                  <h1>Gaming</h1>
                  <p>
                    The Monverse game has two gameplays, namely, the Hardcore
                    gameplay and casual gameplay, to facilitate players.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className='card tokken-cards'>
                <div className='card-body '>
                  <img src='assets/Staking.svg' alt='' />
                  <h1>Blockchain Monverse</h1>
                  <p>
                    The Monteva virus generated monsters on the multi-chain are
                    NFTs. Collect different species of these monsters from
                    different chains to upgrade your level.
                  </p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
        <div className='row mt-5 '>
          <div className='col-md-12 '>
            <Tabs
              defaultActiveKey='buy Monstr via'
              id='uncontrolled-tab-example'
              className='mb-3 col-12 m-auto w-100'>
              <Tab eventKey='buy Monstr via' title='buy Monstr via'>
                <div className='row'>
                  {icons.map((v, i) => {
                    return (
                      <div className='col-md-1 m-auto col-3' key={i}>
                        <div className='sec1-icon-box d-flex justify-content-center '>
                          <img src={v.imgUrl} alt='' className='w-50 object-fit-contain ' />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Tab>
              <Tab eventKey='tokenomics' title='tokenomics'>
                <div className='row'>
                  <div className='col-md-3 m-auto'>
                    <div className='row sec1-icon-box2 text-center'>
                      <div className='col-8'>Strategic round</div>
                      <div className='col-4'>
                        <strong>3.5%</strong>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3 m-auto'>
                    <div className='row sec1-icon-box2 text-center'>
                      <div className='col-8'>Liquidity</div>
                      <div className='col-4'>
                        <strong>50%</strong>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3 m-auto'>
                    <div className='row sec1-icon-box2 text-center'>
                      <div className='col-8'>Core team</div>
                      <div className='col-4'>
                        <strong>1.5%</strong>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3 m-auto'>
                    <div className='row sec1-icon-box2 text-center'>
                      <div className='col-8'>Advisors </div>
                      <div className='col-4'>
                        <strong>1%</strong>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3 m-auto'>
                    <div className='row sec1-icon-box2 text-center'>
                      <div className='col-8'>Play to earn reward</div>
                      <div className='col-4'>
                        <strong>15%</strong>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3 m-auto'>
                    <div className='row sec1-icon-box2 text-center'>
                      <div className='col-8'>Staking reward</div>
                      <div className='col-4'>
                        <strong>15%</strong>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3 m-auto'>
                    <div className='row sec1-icon-box2 text-center'>
                      <div className='col-8'>farms </div>
                      <div className='col-4'>
                        <strong>14%</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey='token address' title='token address'>
                <div className='row  sec1-icon-box2 text-center'>
                  <div className='col-md-6'>
                  BVyHzByZEEuiVynUZShrgYqx95Yp9PWGYxbeGAiNHFru &nbsp; &nbsp;
                    <span
                      className='copy'
                      style={{ cursor: "pointer" }}
                      onClick={copyy}>
                      {" "}
                      <FaRegCopy className='text-light' />{" "}
                    </span>
                  </div>

                  <div className='col-md-6'>
                    Total Supply: &nbsp; <strong>10,000,000 Monstr </strong>
                  </div>
                </div>
              </Tab>
              {/* <Tab eventKey='lp starting' title='lp starting' disabled></Tab> */}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BmcTokken;

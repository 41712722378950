import React from "react";
import {BsStarFill} from 'react-icons/bs'
function BlockMovers() {

  const movers=[
    {imgUrl: '/assets/1.svg', text: 'Kamori'},
    {imgUrl: '/assets/2.svg', text: 'Blockrest'},
    {imgUrl: '/assets/3.svg', text: 'Pewagon'},
    {imgUrl: '/assets/1.svg', text: 'Panghul'},
    {imgUrl: '/assets/4.svg', text: 'Hartbat'},
    {imgUrl: '/assets/3.svg', text: 'Panghul'},
    {imgUrl: '/assets/1.svg', text: 'Moot'},
    {imgUrl: '/assets/4.svg', text: 'Shrumie'},
  ];

  return (
    <div className="container-fluid section-padding blockmover-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="banner" data-aos='zoom-in' data-aos-delay='300'>
              <div className="row">
                <div className="col-md-3 text-center col-2">
                  <img src={"/assets/meta.svg"} alt="" className="banner-big-img"/>
                </div>
                <div className="col-md-6">
                  <h1>Add Monstr to MetaMask</h1>
                  <p>
                    Add our token to MetaMask, so you don’t have to add it in
                    manually later.
                  </p>
                </div>
                <div className="col-md-3 d-flex justify-content-center align-items-center col-8">
                    <button className="block-btn"> <img src={"/assets/meta.svg"} alt="" width={30}/> Add to Metamask</button>
                </div>
              </div>
            </div>
          </div>
        </div>

          <div className="row mt-5 display-3">
              <div className="col-md-12 blockmover-row2">
                  <h1 data-aos='fade-down' data-aos-delay='300'>BLOCKCHAIN MONVERS</h1>
                  <hr />
                  <p data-aos='fade-up' data-aos-delay='300'>NFT monsters are born, caught, and traded across <br className="line-break"/>  multiple chains.</p>
              </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
                <div className="coloured-start">
                  <BsStarFill color="#FF6262" className="stars" data-aos='zoom-in' data-aos-delay='100'/>
                  <BsStarFill color="#6540CF" className="stars" data-aos='zoom-out' data-aos-delay='300'/>
                  <BsStarFill color="#FFCC00" className="stars" data-aos='zoom-in' data-aos-delay='500'/>
                  <BsStarFill color="#0063F7" className="stars" data-aos='zoom-out' data-aos-delay='700'/>
                  <BsStarFill color="#06C270" className="stars" data-aos='zoom-in' data-aos-delay='800'/>
                  <BsStarFill color="#16D3DF" className="stars" data-aos='zoom-out' data-aos-delay='900'/>
                </div>
            </div>
          </div>

          <div className="row">
          {movers.map((v,i)=>{
            return(
            <div className="col-md-3 mt-3" key={i}>
              <div className="block-sec-bg text-center" data-aos='zoom-in' data-aos-delay='300'>
                <img src={v.imgUrl} alt="" />
                <img src={"/assets/Line4.svg"} alt="" className="mt-4" width={260} />
                <h1>{v.text}</h1>
              </div>
            </div>
            )
          })};
      </div>
    </div>
    </div>
  );
}

export default BlockMovers;
